import { theme } from '@chakra-ui/react'
import { createBreakpoints } from "@chakra-ui/theme-tools"
import {upperCase} from 'lodash';

export const spacing = {
	header: '175px', // Top padding after header
	section: '200px', // Padding before/after sections
	groups: '100px', // Margin between groups of elments
	elements: '50px', // grid gaps, etc
	icon: '20px', // icon spacing size
}

// Below extends theme

// Convert HEX to RGB
export const hexToRGB = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}

// Return luminosity value for determining brightest/darkest color
export const lumVal = (col) => {
	var color = "" + col, isHEX = col.indexOf("#") === 0, isRGB = col.indexOf("rgb") === 0;
	if (isHEX) {
		const hasFullSpec = color.length === 7;
		var m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
		if (m) var r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16), g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16), b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
	}
	if (isRGB) {
		var m = color.match(/(\d+){3}/g);
		if (m) var r = m[0], g = m[1], b = m[2];
	}
	if (typeof r != "undefined") return ((r*299)+(g*587)+(b*114))/1000;
}

export const config = {
	initialColorMode: 'light',
	useSystemColorMode: false
}

export const breakpoints = createBreakpoints({
	sm: '420px',
	md: '640px',
	lg: '960px',
	xl: '1200px',
	'2xl': '1440px',
})

export const colors = {
	...theme.colors,
	brand: {
		'slate': '#37474f',
		'lightGray': '#f1f1f1',
		'white': '#ffffff',
		red: '#ff0000',
	}
}

export const typography = {
	fonts: {
		body: 'Figtree',
		heading: 'Abel',
		mono: 'monospace',
	},
	fontSizes: {
		xs: '12px',
		sm: '14px',
		md: '16px',
		lg: '18px',
		xl: '20px',
		'2xl': '22px',
		'3xl': '25px',
		'4xl': '36px',
		'5xl': '45px',
		'6xl': '86px',
	},
	fontWeights: {
		normal: 400,
		medium: 500,
		bold: 700,
	},
	lineHeights: {
		normal: 'normal',
		none: '1',
		shorter: '1.25',
		short: '1.375',
		base: '1.4',
		tall: '1.625',
		taller: '2',
	}
}

export const textStyles = {
	a: {
		color: 'brand.slate',
		transition: 'color 0.2s ease-in-out',
		textDecoration: 'underline',
		'&:hover': {
			color: 'white'
		}
	},
}

export const styles = {
	global: props => ({
		body: {
			// bg: mode('dark', '#000538')(props),
			bg: props.colorMode === 'dark' ? 'brand.slate' : 'brand.white',
			color: props.colorMode === 'dark' ? 'white' : 'brand.slate',
			letterSpacing: '0.4px',
			fontFamily: typography.fonts.body
		},
		a: {
			textDecoration: 'underline',
			transition: 'color 0.3s ease-in-out',
			'&:hover': {
				// color: 'white'
			}
		},
		p: {
			fontSize: 'md',
			marginBottom: '20px',
			lineHeight: typography.lineHeights.base
		},
		'h1, h2, h3, h4, h5, h6': {
			fontWeight: 'normal',
			marginBottom: '50px',
			lineHeight: '1.2',
		},
		'h1, h2': {
			textTransform: 'uppercase'
		},
		'h1, h2, h3': {
			fontFamily: typography.fonts.heading,
		},
		'h4, h5, h6': {
			fontFamily: typography.fonts.body,
		},
		h1: {
			fontSize: ['5xl', '5xl', '6xl'],
		},
		h2: {
			fontSize: ['4xl', '4xl', '5xl'],
			marginBottom: '20px'
		},
		h3: {
			fontSize: ['32px', '32px', '4xl'],
			marginBottom: '10px'
		},
		h4: {
			fontSize: '3xl',
			marginBottom: '10px'
		},
		h5: {
			fontSize: '2xl',
			marginBottom: '10px'
		},
		h6: {
			fontSize: 'lg',
			marginBottom: '10px'
		},
		button: {
			backgroundColor: props.theme.colors.brand.slate,
			color: props.theme.colors.brand.white,
		},
		// FORMS
		'input': {
			minHeight: '55px',
		},
		'input[type="text"]': {
			borderRadius: 0,
		},
		'input[type="submit"]': {
			color: props.theme.colors.brand.lightGray,
			backgroundColor: 'transparent',
			cursor: 'pointer',
			borderRadius: 0,
			border: `1px solid ${props.theme.colors.brand.lightGray}`,
			transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out',
			'&:hover': {
				backgroundColor: props.theme.colors.brand.lightGray,
			},
			'&:focus': {
				borderColor: props.theme.colors.brand.lightGray
			}
		}
	}),
};

export const components = {
	Button: {
		baseStyle: {
			backgroundColor: colors.brand.lightGray,
			color: colors.brand.slate,
			fontFamily: typography.fonts.heading,
			height: 'auto',
			minHeight: '2.5rem',
			'&:focus': {
				outline: 'none',
				boxShadow: 'none'
			}
		},
		variants: {
			'buttonHome': {
				backgroundColor: colors.brand.lightGray,
				height: 'auto',
				outline: 'none',
				borderRadius: '5px',
				transition: `transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out`,
				textTransform: 'uppercase',
				fontWeight: 'normal',
				'&:hover': {
					transform: 'translateY(-2px)',
          boxShadow: `0px 6px 0 ${hexToRGB(colors.brand.slate, 0.6)}`,
				},
				'&:focus': {
					transform: 'translateY(1px)',
          boxShadow: 'none'
        },
			},
			'buttonFooter': {
				height: 'auto!important',
				backgroundColor: colors.brand.lightGray,
				color: colors.brand.slate,
				textTransform: 'uppercase',
				borderRadius: 'none',
				margin: '0 5px 5px 5px',
				display: 'inline-block',
				fontSize: typography.fontSizes['3xl'],
				fontWeight: 'normal',
				lineHeight: 1.5,
				marginTop: '3px',
				'@media only screen and (min-width: 640px)': {
					fontSize: typography.fontSizes['4xl'],
				}
			},
			'buttonCTA': {
				height: 'auto!important',
				backgroundColor: colors.brand.lightGray,
				color: colors.brand.slate,
				textTransform: 'uppercase',
				borderRadius: 'none',
				margin: '0 5px 5px 5px',
				display: 'inline-block',
				fontSize: typography.fontSizes['4xl'],
				fontWeight: 'normal',
				lineHeight: 1.5,
				marginTop: '3px',
			},
			'buttonTalentFilter': {
				color: colors.brand.white,
				textTransform: 'uppercase',
				borderRadius: '0',
				fontFamily: typography.fonts.body,
				fontSize: '12px',
				height: 'auto',
				minHeight: '0',
				transition: 'all 0.3s ease-in-out',
				lineHeight: '1.8',
				border: '1px solid white',
				'&:hover': {
					backgroundColor: `${colors.brand.white}!important`,
					color: colors.brand.slate
				}
			},
			'buttonNewsFilter': {
				color: colors.brand.white,
				textTransform: 'uppercase',
				borderRadius: '0',
				fontFamily: typography.fonts.body,
				fontSize: '12px',
				height: 'auto',
				minHeight: '0',
				transition: 'all 0.3s ease-in-out',
				lineHeight: '1.8',
				border: '1px solid white',
				'&:hover': {
					backgroundColor: `${colors.brand.white}`,
					color: colors.brand.white
				}
			},
			'buttonMorphicSm': {
				height: 'auto!important',
				backgroundColor: colors.brand.lightGray,
				color: colors.brand.slate,
				borderRadius: '9999px',
				textDecoration: 'none!important',
				margin: '0',
				display: 'block',
				fontSize: '0.875rem',
				fontWeight: '500',
				textShadow: '1px 1px 0 white',
				letterSpacing: '0.4px',
				fontFamily: typography.fonts.body,
				lineHeight: '40px',
				paddingLeft: '1.5rem!important',
				paddingRight: '1.5rem!important',
			},
			'buttonGradient': {
				color: colors.brand.white,
				borderRadius: '9999px',
				textDecoration: 'none!important',
				margin: '0',
				display: 'block',
				fontSize: '0.875rem',
				fontWeight: '700',
				lineHeight: '50px',
				letterSpacing: '1px',
				fontFamily: typography.fonts.body,
				textTransform: 'uppercase',
				boxShadow: `4px 4px 10px ${hexToRGB(colors.brand.slate, 0.2)}`,
				padding: '0 2rem',
				transition: 'all 0.3s ease-in-out !important',
				height: '50px!important',
				'&:hover': {
					boxShadow: `2px 2px 8px ${hexToRGB(colors.brand.slate, 0.2)}`
				}
			}
		}
	},
	H1: {
		baseStyle: {
			fontSize: ['5xl', '5xl', '6xl'],
		},
		variants: {
			pageHeader: {
				textAlign: 'center',
				textTransform: 'uppercase',
				lineHeight: 1,
			},
			talentHeader: {
				textAlign: 'left',
				textTransform: 'uppercase',
				lineHeight: 1,
				marginBottom: 0,
			},
			figtree: {
				fontFamily: typography.fonts.body,
			},
			figtreeSm: {
				fontFamily: typography.fonts.body,
				fontSize: ['xs', 'xs', 'xs'],
				textTransform: 'uppercase',
				marginBottom: '5px',
				fontWeight: 'bold'
			},
			figtreeHome: {
				fontFamily: typography.fonts.body,
				fontSize: ['4xl', '4xl', '5xl'],
				textTransform: 'none!important',
				fontWeight:'200'
			}
		}
	},
	H2: {
		baseStyle: {
			fontSize: ['4xl', '4xl', '5xl'],
			// marginBottom: '20px',
		},
		variants: {
			bigLikeh1: {
				fontSize: ['5xl', '5xl', '6xl'],
			},
			figtree: {
				fontFamily: typography.fonts.body,
				textTransform: 'none!important',
				fontWeight: '200'
			},
			figtreeSm: {
				fontFamily: typography.fonts.body,
				fontSize: ['xs', 'xs', 'xs'],
				textTransform: 'uppercase',
				marginBottom: '5px',
				fontWeight: 'bold'
			}
		}
	},
	H3: {
		baseStyle: {
			fontSize: ['32px', '32px', '4xl'],
			// marginBottom: '20px',
		}
	},
	H4: {
		baseStyle: {
			fontSize: '3xl',
		}
	},
	H5: {
		baseStyle: {
			fontSize: '2xl',
		}
	},
	h6: {
		baseStyle: {
			fontSize: 'lg',
		}
	},
	Span: {
		baseStyle: {
			textTransform: 'uppercase',
			// marginBottom: '20px',
		},
		variants: {
			figtreeSm: {
				fontFamily: typography.fonts.body,
				fontSize: ['xs', 'xs', 'xs'],
				textTransform: 'uppercase',
				marginBottom: '5px',
				fontWeight: 'bold'
			}
		}
	},
}

/* lg: '18px',
xl: '20px',
'2xl': '22px',
'3xl': '25px',
'4xl': '36px',
'5xl': '45px',
'6xl': '86px', */

const themeAlopex = {
	config,
	breakpoints,
	colors,
	...typography,
	textStyles,
	styles,
	components
}

export default themeAlopex