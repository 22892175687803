/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react"
import { ChakraProvider, extendTheme, PortalManager } from '@chakra-ui/react'
import themeAlopex from './src/theme/themeAlopex'
import { ColorProvider } from "./src/context/ColorContext"

export const wrapRootElement = ({ element }) => {
  const themeObj = extendTheme({ ...themeAlopex })
  return (
    <ChakraProvider theme={themeObj}>
      <ColorProvider>
        <PortalManager zIndex={1010}>
          {element}
        </PortalManager>
      </ColorProvider>
    </ChakraProvider>
  )
}