import React, { createContext, useState, useEffect } from "react"

const ColorContext = createContext()

export const ColorProvider = ({ children }) => {
	const [color, setColor] = useState({ alpha: '', bravo: '' })
	useEffect (() => {
		const swatches = [
			// row 1
			'#9c27b0',
			'#673ab7',
			'#3f5185',
			'#2196f3',
			'#03a9f4',
			// row 2
			'#65c9de',
			'#009688',
			'#4caf50',
			'#8bc34a',
			'#cbd45a',
			// row 3
			'#ffc107',
			'#ff9800',
			'#ff5722',
			'#f44336',
			'#e91e63',
		]
		let results = []
		let res1 = Math.floor(Math.random() * swatches.length);
		results.push(swatches[res1])
		swatches.splice(res1, 1)
		results.push(swatches[Math.floor(Math.random() * swatches.length)])
		const color1 = results[0]
		const color2 = results[1]
		setColor({alpha: color1, bravo: color2})
		if (typeof window !== 'undefined') {
			window.globalColor1 = color1;
			window.globalColor2 = color2;
		}
	}, [])
	
	useEffect (() => {
		window.globalColor1 = color.alpha;
		window.globalColor2 = color.bravo;
	}, [color])

	return (
		<ColorContext.Provider value={{ color, setColor }}>
			{children}
		</ColorContext.Provider>
	)
}

export default ColorContext